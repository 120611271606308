import { render, staticRenderFns } from "./nodalPointInfo.vue?vue&type=template&id=89b47b60&scoped=true"
import script from "./nodalPointInfo.vue?vue&type=script&lang=js"
export * from "./nodalPointInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89b47b60",
  null
  
)

export default component.exports