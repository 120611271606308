<template>
  <!-- 公司信息 -->
  <div class="outer-page">
    <!-- v-if="form.roleType=='40'" -->
    <firm-info :formInfo='form'></firm-info>

    <!-- 种植户 -->
    <!-- <farmer-info v-if="form.roleType=='30'"></farmer-info> -->

    <!-- 社会化组织 -->
    <!-- <socialization-info v-if="form.roleType=='50'"></socialization-info> -->

    <!-- 集散点 -->
    <!-- <nodalPointInfo v-if="form.roleType=='60'"></nodalPointInfo> -->

  </div>
</template>

<script>
// import moment from "moment";
// import until from "../../common/util";
// import searchMap from "@/components/searchMap";
import QRCode from "qrcodejs2";
import firmInfo from './firmInfo.vue'
import farmerInfo from './farmerInfo.vue'
import socializationInfo from './socializationInfo.vue'
import nodalPointInfo from "./nodalPointInfo.vue"
export default {
  name: "",
  components: {
    firmInfo,
    farmerInfo,
    socializationInfo,
    nodalPointInfo,
  },

  data() {
    return {
      form: {},
    };
  },
  methods: {
    //提交数据
    // submit() {
    //   this.$refs.form.validate().then((res) => {
    //     if (!res) return;
    //     let params = {...this.form};
    //     this.form.typeInfo.forEach((item) => {
    //       params.type.push(item.split("/")[0]);
    //       params.typeName.push(item.split("/")[1]);
    //     });
    //      params.regionCode = this.form.region.join(",");
    //     this.$post(this.$api.FAMRMING.UPDATE, params)
    //       .then(() => {
    //         this.$Message.success("修改成功！");
    //         this.getInfomation()
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   });
    // },

    //获取用户信息
    getInfomation() {
      this.$post(this.$api.FIRM_ADMINISTRATOR.LIST, {
        companyNo: localStorage.getItem("companyNo"),
      }).then((res) => {
        this.form = res;
      });
    },

  },
  mounted() {
    this.getInfomation();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
</style>