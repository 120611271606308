<template>
  <div>
    <!-- 种植户 -->
    <div class="container" style="display: flex">
      <Card style="width: 1000px" title="公司信息">
        <Form ref="form" :model="form" :label-width="140" :rules="rules">
          <Row>
            <Col span="11">
              <FormItem label="企业名称" prop="planterName">
                <Input
                  clearable
                  placeholder="请输入企业名称"
                  v-model="form.planterName"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="统一社会信用代码" prop="creditCode">
                <Input
                  clearable
                  placeholder="请输入统一社会信用代码"
                  v-model="form.creditCode"
                ></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="联系人" prop="name">
                <Input
                  clearable
                  placeholder="请输入联系人名称"
                  v-model="form.name"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="电话号码" prop="tel">
                <Input
                  clearable
                  placeholder="请输入电话号码"
                  v-model="form.tel"
                ></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="企业类型" prop="enterpriseType">
                <Select
                  clearable
                  placeholder="请输入企业类型"
                  v-model="form.enterpriseType"
                >
                  <Option
                    v-for="(item, index) in companyData"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="土地规模">
                <Input>
                  <span slot="append">㎡</span>
                </Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="所属区域" prop="regionCode">
                <Cascader
                  :data="areaList"
                  v-model="form.regionCode"
                  change-on-select
                  :load-data="loadData"
                ></Cascader>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="详细地址" prop="farmerAddress">
                <Input
                  placeholder="请输入种植地址"
                  v-model="form.farmerAddress"
                >
                  <span
                    slot="append"
                    class="iconfont"
                    style="cursor: pointer"
                    @click="mapModelShow"
                    >&#xe648;</span
                  >
                </Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="图片上传">
                <upload-image
                  :max="1"
                  :clear-flag="!modal.show"
                  v-model="form.logo"
                ></upload-image>
              </FormItem>
            </Col>
          </Row>
        </Form>
        <div>
          <p slot="footer" style="text-align: center">
            <Button type="primary" @click="submit">修改</Button>
          </p>
        </div>
      </Card>

      <!-- 二维码 -->
      <Card style="flex: 1; margin-left: 30px">
        <div id="qrcode1"></div>
      </Card>
    </div>

     <Modal v-model="mapModal" fullscreen>
      <search-map
        v-if="mapModal"
        :currentData="currentData"
        @back-location="dealMapData"
      ></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModal = false)">提交</Button>
        <Button @click="() => (mapModal = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import searchMap from "@/components/searchMap";
import QRCode from "qrcodejs2";
export default {
  name: "",
  components: {
    searchMap,
  },
  data() {
    return {
      config: {
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      currentData: null,
      areaList: [],
      catData: [],
      cityList: [
        { value: "1", label: "是" },
        { value: "2", label: "否" },
      ],
      companyData: [],
      form: {},
      rules: {
        name: [{ required: true, message: "请输入姓名" }],
        birthday: [{ required: true, type: "date", message: "请选择出生年月" }],
        phone: [{ required: true, message: "请输入联系电话" }],
      },
      mapModal: false,
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
    };
  },
  methods: {
    //提交数据
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        this.$post(this.$api.company.EDIT, params)
          .then(() => {
            this.$Message.success("修改成功！");
            this.getInfomation();
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    //获取用户信息
    getInfomation() {
      this.$post(this.$api.FIRM_ADMINISTRATOR.LIST, {
        companyNo: localStorage.getItem("companyNo"),
      }).then((res) => {
        this.form = res;
        this.form.logo = this.getImgUrl(res.servicePath, res.logo);
      });
    },

    mapModelShow() {
      this.currentData = this.form.thirdLongLat;
      this.mapModal = true;
    },
    loadData(item, callback) {
        console.log(1111);
      item.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.hasChild == "true") {
              item.loading = false;
              item.children = [];
            }
          });
          item.children = res;
          callback();
        })
        .finally(() => {
          item.loading = false;
        });
    },
    dealMapData(mapData) {
      this.form.farmerAddress = mapData.address;
      this.form.thirdLongLat = mapData.thirdLongLat;
    },
    //二维码
    qrcodeInit() {
      let qrcode = new QRCode(document.getElementById("qrcode1"), {
        text: "http://jindo.dev.naver.com/collie",
        width: 128,
        height: 128,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    //企业类型
    getCompanyCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "203",
      }).then((res) => {
        this.companyData = res.list;
      });
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: "500153",
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          item.loading = false;
          item.children = [{ label: "无", value: "", disabled: "ture" }];
        });
        this.areaList = res;
      });
    },
    //养殖品种
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: "2",
      }).then((res) => {
        this.catData = res.list;
      });
    },
  },
  mounted() {
    this.getInfomation();
    this.getCompanyCategory();
    this.getAreaList();
    this.qrcodeInit();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped>
</style>