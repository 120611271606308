<template>
  <div>
    <!-- 社会化组织 -->
    <div class="container" style="display: flex">
      <Card style="width: 1000px" title="公司信息">
        <Form :label-width="140" ref="form" :model="form" :rules="rules">
          <Row>
            <Col span="11">
              <FormItem label="社会化组织名称" prop="serviceName">
                <Input
                  v-model="form.serviceName"
                  placeholder="请输入社会化组织名称"
                  clearable
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="业务范围" prop="businessScope">
                <Select
                  transfer
                  clearable
                  placeholder="请输入企业类型"
                  multiple
                  v-model="form.businessScope"
                >
                  <Option
                    v-for="(item, index) in businessScopeData"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="联系人" prop="name">
                <Input
                  clearable
                  placeholder="请输入联系人"
                  v-model="form.name"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="联系电话" prop="tel">
                <Input
                  clearable
                  placeholder="请输入联系电话"
                  v-model="form.tel"
                ></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="社会统一信用代码" prop="creditCode">
                <Input
                  clearable
                  placeholder="请输入社会统一信用代码"
                  v-model="form.creditCode"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="企业类型" prop="enterpriseType">
                <Select
                  clearable
                  placeholder="请输入企业类型"
                  v-model="form.enterpriseType"
                >
                  <Option
                    v-for="(item, index) in companyData"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="省市区编码" prop="region">
                <Cascader
                  :data="areaList"
                  v-model="form.region"
                  :load-data="loadData"
                  placeholder="请选择区域"
                ></Cascader>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="联系地址" prop="serviceAddress">
                <Input
                  clearable
                  placeholder="请输入联系地址"
                  v-model="form.serviceAddress"
                ></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="营业期限" prop="businessTerm">
                <Input
                  clearable
                  placeholder="请输入营业期限"
                  v-model="form.businessTerm"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="位置" prop="thirdLongLat">
                <Input
                  v-model="form.thirdLongLat"
                  disabled
                  placeholder="点击右侧图标选择位置"
                >
                  <span
                    slot="append"
                    class="iconfont"
                    @click="mapModalShow"
                    style="cursor: pointer"
                    >&#xe648;</span
                  >
                </Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
        <div>
          <p slot="footer" style="text-align: center">
            <Button type="primary" @click="submit">修改</Button>
          </p>
        </div>
      </Card>

      <!-- 二维码 -->
      <Card style="flex: 1; margin-left: 30px">
        <div id="qrcode"></div>
      </Card>
    </div>

    <Modal v-model="mapModel" title="选择位置" fullscreen>
      <search-map
        v-if="mapModel"
        :currentData="currentData"
        @back-location="dealMapData"
      ></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModel = false)">提交</Button>
        <Button @click="() => (mapModel = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import searchMap from "@/components/searchMap";
export default {
    components:{searchMap},
  data() {
    return {
      form: {},
      rules: {},
      businessScopeData: [],
      companyData: [],
      mapModel: false,
      currentData:null,
      areaList:[]
    };
  },
  methods: {
      mapModalShow() {
      this.currentData = this.form.thirdLongLat;
      this.mapModel = true;
    },
    dealMapData(positionData) {
      this.form.thirdLongLat = positionData.thirdLongLat;
    },
    //二维码
    qrcodeInit() {
      let qrcode = new QRCode(document.getElementById("qrcode"), {
        text: "http://jindo.dev.naver.com/collie",
        width: 128,
        height: 128,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    submit() {
        this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = this.form;
        // params.category = this.user.category + 1;
        // params.regionCode = this.form.region.join(",");
        params.businessScope = this.form.businessScope.join(",");
        // params.businessScopeName = this.form.businessScope.join(",");
        delete params.region;
        let url = this.$api.company.EDIT
    
        this.$post(url, params).then(() => {
          this.$Message.success("修改成功！");
          this.getInfomation();
        });
      });
    },
    //业务范围接口
    getBusinessScopeData() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "255",
      }).then((res) => {
        this.businessScopeData=res.list
        // console.log(res)
      });
    },
    //企业类型
    getCompanyCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "203",
      }).then((res) => {
        this.companyData = res.list;
      });
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: "100000",
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          item.loading = false;
          item.children = [];
        });
        this.areaList = res;
      });
    },
     loadData(item, callback) {
      item.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.hasChild == "true") {
              item.loading = false;
              item.children = [];
            }
          });
          item.children = res;
          callback();
        })
        .finally(() => {
          item.loading = false;
        });
    },
    getInfomation() {
      this.$post(this.$api.FIRM_ADMINISTRATOR.LIST, {
        companyNo: localStorage.getItem("companyNo"),
      }).then((res) => {
        this.form = res;
      });
    },
  },
  mounted() {
    this.qrcodeInit();
    this.getCompanyCategory()
    this.getBusinessScopeData()
    this.getAreaList()
    this.getInfomation()
  },
};
</script>

<style lang="less" scoped>
</style>