<template>
  <div>
    <!-- 种植户 -->
    <div class="container" style="display: flex">
      <Card style="width: 1000px" title="公司信息">
        <Form :label-width="140" ref="form" :model="form" :rules="rules">
          <Row>
            <Col span="11">
              <FormItem label="集散点名称" prop="serviceName">
                <Input
                  v-model="form.serviceName"
                  placeholder="请输入集散点名称"
                  clearable
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="联系人" prop="name">
                <Input
                  clearable
                  placeholder="请输入联系人"
                  v-model="form.name"
                ></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="联系电话" prop="tel">
                <Input
                  clearable
                  placeholder="请输入联系电话"
                  v-model="form.tel"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="企业类型" prop="enterpriseType">
                <Select
                  clearable
                  placeholder="请输入企业类型"
                  v-model="form.enterpriseType"
                >
                  <Option
                    v-for="(item, index) in companyData"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="统一社会信用代码" prop="creditCode">
                <Input
                  clearable
                  placeholder="请输入统一社会信用代码"
                  v-model="form.creditCode"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="省市区编码" prop="region">
                <!-- <Input
            v-model="form.regionCode"
            placeholder="请输入省市区编码"
            clearable
            style="width: 300px"
          ></Input> -->
                <Cascader
                  :data="areaList"
                  v-model="form.region"
                  :load-data="loadData"
                  placeholder="请选择区域"
                ></Cascader>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="联系地址" prop="serviceAddress">
                <Input
                  clearable
                  placeholder="请输入联系地址"
                  v-model="form.serviceAddress"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="营业期限" prop="businessTerm">
                <Input
                  clearable
                  placeholder="请输入营业期限"
                  v-model="form.businessTerm"
                ></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="业务范围" prop="businessScope">
                <Input
                  clearable
                  placeholder="请输入业务范围"
                  v-model="form.businessScopeName"
                  type="textarea"
                ></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem label="位置" prop="thirdLongLat">
                <Input
                  v-model="form.thirdLongLat"
                  disabled
                  placeholder="点击右侧图标选择位置"
                >
                  <span
                    slot="append"
                    class="iconfont"
                    @click="mapModalShow"
                    style="cursor: pointer"
                    >&#xe648;</span
                  >
                </Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="图片上传">
                <upload-image
                  :max="1"
                  :clear-flag="!modal.show"
                  v-model="form.logo"
                ></upload-image>
              </FormItem>
            </Col>
          </Row>
        </Form>

        <div>
          <p slot="footer" style="text-align: center">
            <Button type="primary" @click="submit">修改</Button>
          </p>
        </div>
      </Card>

      <!-- 二维码 -->
      <Card style="flex: 1; margin-left: 30px">
        <div id="qrcode"></div>
      </Card>
    </div>

    <Modal v-model="mapModel" title="选择位置" fullscreen>
      <search-map
        v-if="mapModel"
        :currentData="currentData"
        @back-location="dealMapData"
      ></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModel = false)">提交</Button>
        <Button @click="() => (mapModel = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import searchMap from "@/components/searchMap";
import QRCode from "qrcodejs2";
export default {
    components:{searchMap},
  data() {
    return {
      form: {},
      rules: {},

      companyData: [],
      //省市区列表
      areaList: [],
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      mapModel: false,
    };
  },
  methods: {
    submit() {

         this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = this.form;
        // params.category = this.user.category + 1;
        // params.regionCode = this.form.region.join(",");
        // let index = this.form.logo ? this.form.logo.indexOf("/", 10) : -1;
        // if (index !== -1) {
        //   params.logo = this.form.logo.substring(index + 1);
        // }
        let url = this.$api.company.EDIT
        this.$post(url, params).then(() => {
          this.$Message.success("修改成功！");
          this.getList();
        });
      });

    },
    mapModalShow() {
      this.currentData = this.form.thirdLongLat;
      this.mapModel = true;
    },
    dealMapData(positionData) {
      this.form.thirdLongLat = positionData.thirdLongLat;
    },
    loadData(item, callback) {
      item.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.hasChild == "true") {
              item.loading = false;
              item.children = [];
            }
          });
          item.children = res;
          callback();
        })
        .finally(() => {
          item.loading = false;
        });
    },
    //企业类型
    getCompanyCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "203",
      }).then((res) => {
        this.companyData = res.list;
      });
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: "100000",
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          item.loading = false;
          item.children = [];
        });
        this.areaList = res;
      });
    },
    //二维码
    qrcodeInit() {
      let qrcode = new QRCode(document.getElementById("qrcode"), {
        text: "http://jindo.dev.naver.com/collie",
        width: 128,
        height: 128,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    getList(){
        this.$post(this.$api.FIRM_ADMINISTRATOR.LIST, {
        companyNo: localStorage.getItem("companyNo"),
      }).then((res) => {
        this.form = res;
      });
    }
  },
  mounted() {
      this.getCompanyCategory()
      this.getAreaList()
      this.qrcodeInit()
      this.getList()
  },
};
</script>

<style lang="less" scoped>
</style>